/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import {useStaticQuery, graphql} from "gatsby";

function SEO({lang, title}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
    );

    return (
        <Helmet>
            <title>{title} | {site.siteMetadata.title}</title>
            {/*<link rel="stylesheet" href="static/bootstrap.min.css"/>*/}
            {/*<link rel="stylesheet" href="static/academicons.min.css"/>*/}
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js"
                    integrity="sha384-7+zCNj/IqJ95wo16oMtfsKbZ9ccEh31eOz1HGyDuCQ6wgnyJNSYdrPa03rtR1zdB"
                    crossOrigin="anonymous" />
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.min.js"
                    integrity="sha384-QJHtvGhmr9XOIpI6YVutG+2QOK9T+ZnN4kzFN1RtK3zEFEIsxhlmWl5/YESvpZ13"
                    crossOrigin="anonymous" />
            <script src="https://kit.fontawesome.com/4d1f27b2f7.js"
                crossOrigin="anonymous"/>
        </Helmet>
    );
}

SEO.defaultProps = {};

SEO.propTypes = {
    title: PropTypes.string.isRequired,
};

export default SEO;
