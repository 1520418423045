import { Link } from "gatsby";
import React from "react";
// import AniLink from "gatsby-plugin-transition-link/AniLink";

const Footer = () => {
    return (
        <footer>
            <div className="w-100 text-center mt-4 mb-3 text-muted">
              © {new Date().getFullYear()} - Manuel Dahnert - <Link to="/imprint/">Imprint</Link>
            </div>
        </footer>
    );
};

export default Footer;
